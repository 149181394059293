<template>
	<div class="bulMarketSet el-content">
		<a-tabs defaultActiveKey @change="getSetUp">
		    <a-tab-pane key="trade" tab="交易设置">
				<com-power-form form-type="card" :form-data="[
					{title:'光大金融开放平台',param:[
						{label:'身份标识(appId)',type:'radio',value:'',key:'bank_ceb_debug',dataType:'string',
							options:[
								{label:'测试',value:'1'},
								{label:'正式',value:'0'},
							]
						},
						{label:'测试接口地址',type:'text',value:'',key:'bank_ceb_test_url',dataType:'string'},
						{label:'测试文件地址',type:'text',value:'',key:'bank_ceb_test_images_url',dataType:'string'},
						{label:'商户编号',type:'text',value:'',key:'bank_ceb_user_id',dataType:'string'},
						{label:'证书文件',type:'upload-cert',value:'',key:'bank_ceb_rsa_prv',dataType:'string'},
						{label:'证书密码',type:'text',value:'',key:'bank_ceb_rsa_prv_pass',dataType:'string'},
					]},
					{title:'光大金融监管',param:[
						{label:'SDK路径',type:'text',value:'',key:'bank_ceb_fcs_sdk_path',dataType:'string'},
						{label:'机构号',type:'text',value:'',key:'bank_ceb_fcs_inst_id',dataType:'string'},
						{label:'商户号',type:'text',value:'',key:'bank_ceb_fcs_bus_id',dataType:'string'},
						{label:'保证金账号',type:'text',value:'',key:'bank_ceb_fcs_bail_account',dataType:'string'},
						{label:'自有资金账号',type:'text',value:'',key:'bank_ceb_fcs_self_account',dataType:'string'},
					]}
				]"></com-power-form>
			</a-tab-pane>
		    <a-tab-pane key="basic" tab="基本设置">
				<com-power-form :form-data="[
					{label:'展示主标题',type:'text',value:'',key:'bull_main_title',dataType:'string'},
					{label:'展示副标题',type:'text',value:'',key:'bull_second_title',dataType:'string'},
					{label:'交易码小标题',type:'text',value:'',key:'bull_trade_qrcode_title',dataType:'string'},
					{label:'牛市LOGO',type:'image',value:'',key:'bull_logo',dataType:'string'},
				]"></com-power-form>
			</a-tab-pane>
		    <a-tab-pane key="rules1" tab="电子账户使用协议">
				<com-power-form :form-data="[
					{label:'电子账户使用协议',type:'editor',value:'',key:'bull_account_use_agreement',dataType:'string'},
				]"></com-power-form>
			</a-tab-pane>
			<a-tab-pane key="rules2" tab="采购协议">
				<com-power-form :form-data="[
					{label:'采购协议',type:'editor',value:'',key:'bull_privacy_agreement',dataType:'string'},
				]"></com-power-form>
			</a-tab-pane>
		</a-tabs>
	</div>
	
</template>

<script>
import uploadCert from '@/components/public/upload-cert.vue'
import comPowerForm from '@/components/form/com-power-form.vue'
export default{
	components:{
		uploadCert,
		comPowerForm
	},
	setup(){
		
	}
}
</script>

<style>
</style>
